.guide-11 {
  & h2 {
    margin-bottom: 20px;
  }

  &__list {
    width: 750px;

    list-style: none;

    @include viewport--md {
      width: 540px;
    }

    @include viewport--md {
      width: 100%;
    }
  }

  &__item {
    margin-left: 37px;
    padding-bottom: 17px;

    font-size: 16px;
    line-height: 22px;

    &:not(:first-child) {
      padding-top: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $underline-color-2;
    }

    & p {
      pointer-events: none;
    }
  }
}
