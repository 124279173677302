.guide {
  // position: relative;
  width: 100%;
  height: 100%;
  // padding-top: 70px;

  &--pointed {
    padding-bottom: 10px;
  }
}

.guide__container {
  position: relative;
  padding: 70px 20px 10px;

  @include viewport--lg-2 {
    padding: 10px 16px 10px;
  }

  // &--1 {
  //   padding-bottom: 90px;
  // }

  // &--2 {
  //   padding-bottom: 50px;
  // }

  // &--3 {
  //   padding-bottom: 30px;
  // }
}

.guide__header {
  margin-bottom: 20px;
  margin-top: 8px;

  @include viewport--lg-2 {
    margin-bottom: 16px;
  }
}

.guide__title {
  position: relative;
  padding-left: 36px;
  margin-top: 17px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;

  &::before {
    counter-increment: manual;
    content: counter(manual);
    position: absolute;
    top: 1px;
    left: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    color: $color-default-white;
    background-color: $color-blue;
  }
}

.guide__criterion {
  margin-bottom: 8px;
  margin-left: 39px;
  font-size: 12px;
  line-height: 16px;
  color: $color-submarine;

  @include viewport--lg-2 {
    margin-left: 10px;
  }
}

.guide__list-ol,
.guide__list-ul {
  list-style: none;
  font-size: 16px;
  line-height: 22px;
}

.guide__list-ol {
  counter-reset: guide;
}

.guide__list-ol li,
.guide__list-ul li {
  position: relative;
  padding-left: 20px;

  &:not(:last-child) {
    padding-bottom: 3px;
  }

  &::before {
    position: absolute;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-blue;
  }
}

.guide__list-ol > li::before {
  top: 0;
  counter-increment: guide;
  content: counter(guide) ".";
}

.guide__list-ul li::before {
  content: "•";
  top: 2px;
}

.guide__img {
  position: relative;
  overflow: hidden;
}

.guide__img .guide__img-canvas {
  transition: opacity $transition-default;
  opacity: 1;
}

.guide__img--hover .guide__img-canvas {
  opacity: 0.2;
}

.guide__img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity $transition-default;
}

.guide__img-overlay.visible {
  opacity: 1;
}

.guide__item-hover {
  cursor: pointer;
}

.guide__wrap-link {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 10px;
  align-items: flex-end;
}

.guide__wrap-link > a {
  margin-bottom: 10px;
  color: $color-blue;
  border-bottom: 1px solid $underline-color;
  transition: opacity $transition-default;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
}
