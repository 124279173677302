.guide-4 {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 60px;
}

.guide-4 h2 {
  margin-bottom: 17px;
}

.guide-4 h3,
.guide-4 h4 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.guide-4 h3 {
  margin-left: 38px;
  margin-bottom: 17px;
}

.guide-4 h4 {
  margin-bottom: 6px;
}

.guide-4__note {
  width: 710px;
  margin-left: 38px;
  margin-bottom: 15px;

  @include viewport--md {
    width: 578px;
  }

  @include viewport--sm {
    width: auto;
  }
}

.guide-4__note-break {
  @include viewport--lg {
    display: none;
  }
}

.guide-4__list-equipment {
  margin-left: 20px;
  padding-bottom: 30px;

  &--counter {
    counter-reset: guide 2;
  }

  @include viewport--md {
    padding-bottom: 20px;
  }
}

.guide-4__list-inner {
  margin-left: -20px;
  margin-bottom: 20px;

  @include viewport--lg-2 {
    column-count: 2;
  }
}

.guide-4__link-next {
  position: relative;
  display: inline-flex;
  margin-bottom: 20px;
  margin-left: 59px;
  padding-bottom: 3px;
  font-weight: 500;
  color: $color-blue;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 278px;
    height: 1px;
    background-color: rgba($color-blue, 0.2);
  }

  @include viewport--md {
    margin-bottom: 10px;
    margin-left: 40px;
  }
}

.guide-4__icon-arrow {
  position: absolute;
  top: 3px;
  right: -25px;
}
