.attention {
  position: relative;
  height: 60px;
  padding-left: 27px;
  display: flex;
  //overflow-y: hidden;
  color: $color-default-white;

  @include viewport--lg-2 {
    height: 75px;
    padding-left: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 15px;
    background-color: $color-orange;

    @include viewport--lg-2 {
      right: 0;
      left: unset;
      width: 13px;
      z-index: 1;
    }
  }

  &::before {
    top: 0;
    height: 41px;


    @include viewport--lg-2 {
      top: 7px;
      height: 33px;
    }
  }

  &::after {
    bottom: 0;
    height: 10px;

    @include viewport--lg-2 {
      bottom: 18px;
      height: 9px;
    }
  }
}

.attention__wrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 9px 12px;
  flex-grow: 1;
  background-color: $color-blue;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 19px;
    height: 100%;
    border-top: 30px solid $color-blue;
    border-bottom: 30px solid $color-blue;
    border-right: 19px solid transparent;

    @include viewport--lg-2 {
      display: none;
    }
  }

  @include viewport--lg-2 {
    padding: 16px 12px;
  }
}

.attention__wrap p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  overflow: hidden;
}

.attention__wrap a {
  transition: opacity $transition-default;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.attention svg {
  margin-left: 15px;
  width: 35px;

  @include viewport--lg-2 {
    display: none;
  }
}
