.main-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  padding: 26px 38px 35px 46px;
  background-color: $color-blue;
  border-radius: 10px;

  @include viewport--lg-2 {
    width: 696px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 16px 0 10px 0;
    background-color: inherit;
  }

  @include viewport--sm {
    width: auto;
    max-width: 735px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.main-header__title {
  font-size: 36px;
  text-transform: uppercase;
  color: $color-default-white;

  @include viewport--lg-2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-transform: inherit;
    color: inherit;
  }
}

.main-header__text {
  font-size: 26px;
  line-height: 36px;
  color: $color-orange;

  @include viewport--lg-2 {
    display: none;
  }
}

.main-header__logo {
  width: 249px;
  height: 53px;

  @include viewport--lg-2 {
    display: none;
  }
}

.main-header__btn {
  display: none;
  outline: none;

  &:hover {
    opacity: 0.5;
  }

  @include viewport--lg-2 {
    display: block;
    width: 22px;
    height: 18px;
    padding: 0;
    background: url("../img/svg/burger.svg") no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &--close {
      position: absolute;
      top: 20px;
      right: 2%;
      z-index: 110;
      background: url("../img/svg/burger-close.svg") no-repeat;
    }
  }
}
