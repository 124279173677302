.guide-6__wrap {
  margin-left: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;

  @include viewport--md {
    width: 536px;
  }

  @include viewport--sm {
    width: auto;
  }
}

.guide-6 h2 {
  margin-bottom: 11px;
}

.guide-6__list li {
  padding-left: 15px;
}

.guide-6 h3 {
  margin: 20px 0 4px 15px;
  font-weight: 500;
}

.guide-6__text-break {
  @include viewport--md {
    display: none;
  }
}
