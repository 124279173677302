.anchor-link__break-one {
  @include viewport--lg {
    display: none;
  }
}

.anchor-link__break-two {
  @include viewport--md {
    display: none;
  }
}
