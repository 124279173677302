.modal {
  // @media (min-width: 600px) {
  // display: none;
  display: none;

  // &--show {
  //   display: flex;
  // }
  // }

  @media (max-width: 599px) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    margin: 31px 20px;
    padding: 31px 20px;
    background-color: $color-default-white;
    border-radius: 10px;
  }
}

.modal__logo {
  width: 202px;
  height: 43px;
}

.modal__mobile {
  object-fit: cover;
  width: 73px;
  height: 65px;
  margin-bottom: 20px;
}

.modal__text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: $color-blue;
}
