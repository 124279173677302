.guide-2 {
  & h2 {
    margin-bottom: 20px;

    @include viewport--lg-2 {
      max-width: 80%;
    }
  }

  &__text {
    margin-top: 15px;
    padding-left: 28px;
    padding-bottom: 10px;

    & p {
      font-size: 16px;
    }

    & > p {
      margin-left: 10px;
      margin-bottom: 5px;

      line-height: 22px;
    }
  }

  &__functions-list {
    margin-bottom: 15px;
  }

  &__functions-list li {
    padding-left: 10px;
  }

  &__types-list {
    display: flex;
    flex-wrap: wrap;
    width: 837px;
    margin-top: 15px;

    color: $color-blue;

    list-style: none;

    @include viewport--md {
      flex-direction: column;
      width: 637px;
    }
  }

  &__types-item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 16px 10px;

    background-color: $color-alice-blue;
    border-radius: 6px;

    @include viewport--md {
      margin-bottom: 16px;
    }

    &--first {
      width: 198px;

      @include viewport--md {
        width: 486px;
      }
    }

    &--second {
      width: 335px;

      @include viewport--md {
        width: 486px;
      }
    }

    &--third {
      width: 244px;

      @include viewport--md {
        width: 486px;
      }
    }

    & p {
      line-height: 20px;

      pointer-events: none;
    }

    & p + p {
      margin-top: 5px;
    }
  }
}

.guide-2__text b {
  font-weight: 500;
}
