.guide-9 {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 41px;
}

.guide-9 h2 {
  margin-top: 6px;
  margin-bottom: 11px;
}

.guide-9 .guide__wrap-link {
  margin-bottom: 0;

  font-size: 18px;

  @include viewport--md {
    align-items: flex-start;
    font-size: 14px;
    line-height: 22px;
  }
}

.guide-9__wrap {
  width: 690px;
  margin-left: 22px;
  padding-bottom: 10px;

  @include viewport--md {
    width: 550px;
  }

  @include viewport--md {
    width: auto;
  }
}

.guide-9__note {
  margin-left: 17px;
  margin-bottom: 15px;
}

.guide-9__note span {
  font-weight: 500;
}

.guide-9__wrap > h3 {
  margin-left: 17px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.guide-9__wrap li {
  padding-left: 17px;
}
