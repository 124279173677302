.note {
  display: none;

  @include viewport--lg-2 {
    position: absolute;
    top: 50px;
    right: 0;
    display: block;
    width: 696px;
    margin: 0 auto;
    text-align: right;
    color: #757d96;

    &--hide {
      display: none;
    }
  }
}

.note__icon {
  position: absolute;
  top: 2px;
  right: 330px;
}
