// .guide-1 {
//   padding-bottom: 70px;
// }

.guide-1 h2 {
  margin-bottom: 11px;
}

.guide-1 .guide__list-ol {
  margin-left: 17px;
}

.guide-1__list {
  width: 703px;
  padding-bottom: 10px;

  @include viewport--md {
    width: 589px;
  }

  @include viewport--sm {
    width: 100%;
  }
}

.guide-1__list li:not(:last-child) {
  padding-bottom: 5px;
}
