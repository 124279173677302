.guide-10 {
  font-size: 16px;
  line-height: 22px;
  color: $color-black;
}

.guide-10 h2 {
  margin-bottom: 12px;

  &::before {
    padding-right: 2px;
  }
}

.guide-10__wrap {
  margin-left: 35px;
}

.guide-10__wrap > p {
  &:first-child {
    @include viewport--md {
      margin-bottom: 16px;
    }
  }
}

.guide-10__list {
  margin-top: 8px;
  margin-bottom: 5px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.guide-10__list-item {
  width: 290px;
  padding: 13px 16px 17px 16px;
  border-radius: 6px;
  color: $color-blue;
  background-color: $color-alice-blue;
  margin-bottom: 15px;

  &:not(:nth-child(3n + 1)) {
    margin-left: 15px;
  }

  @include viewport--sm {
    width: 48%;
  }
}

.guide-10__list-item > p {
  pointer-events: none;

  &:first-child {
    font-weight: 700;
    margin-bottom: 5px;
  }
}

.guide-10__list + p {
  padding-top: 0;
}

.guide-10__note {
  width: 605px;
  padding-bottom: 16px;
  padding-top: 18px;

  &:not(:last-child) {
    border-bottom: 1px solid $underline-color-2;
  }

  @include viewport--md {
    width: 560px;
  }

  @include viewport--sm {
    width: auto;
  }
}
