.guide-0__content {
  display: flex;
  width: 939px;
  min-height: 443px;
  margin-top: 20px;
  z-index: 0;

  @include viewport--md {
    width: 665px;
    min-height: 329px;
    margin: 0 auto;
  }

  @include viewport--sm {
    width: 100%;
  }
}

.guide-0__list .guide__criterion {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}

.guide-0__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @include viewport--md {
    object-fit: cover;
    width: 664px;
    height: 329px;
  }
}

.guide-0__icon {
  width: 44px;
  flex-shrink: 0;
}

.guide-0 .guide__wrap-link {
  align-items: flex-start;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 14px;
}

.guide-0__list {
  width: 210px;
  margin-top: 90px;
  margin-left: 80px;

  @include viewport--md {
    margin-top: 60px;
  }
}

.guide-0__list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  &:not(:last-child) {
    margin-bottom: 15px;

    @include viewport--md {
      margin-bottom: 0;
    }
  }
}

.guide-0__list p {
  font-size: 12px;
  line-height: 13px;
  color: $color-blue;
  text-align: right;
}

.guide-0__list a {
  border-bottom: 1px solid $color-grey;
  transition: opacity $transition-default;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
}

.guide-0__hyphens {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.guide-0__item-icon {
  padding-bottom: 0;
}
