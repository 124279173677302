.pointer {
  position: absolute;
  right: 0;
  top: 529px;

  display: flex;
  align-items: center;
  padding: 9px 7px;

  background-color: $color-hawkes-blue;
  border-radius: 20px 0 0 20px;

  & p {
    display: none;
    max-width: 247px;
    margin-left: 7px;

    color: $color-blue;
    font-size: 12px;
    line-height: 14px;
  }

  @include viewport--md {
    display: none;
  }
}

.guide-1 .pointer {
  & p {
    display: block;
    border-radius: 100px 0 0 100px;
  }
}

.guide-2 .pointer {
  top: 585px;
}

.guide-5 .pointer {
  top: 575px;
}
