.guide-5 {
  &__wrap-link {
    & > a {
      margin-bottom: 0;

      font-size: 18px;

      @include viewport--md {
        font-size: 14px;
        line-height: 22px;
      }
    }

    @include viewport--md {
      align-items: flex-start;
    }
  }

  & h2 {
    margin-bottom: 13px;
  }

  &__list {
    width: 725px;
    margin-left: 17px;
    padding-bottom: 10px;

    & li {
      &:last-child {
        padding-bottom: 16px;
      }
    }

    @include viewport--md {
      width: 565px;
    }

    @include viewport--md {
      width: auto;
    }
  }

  &__item {
    font-size: 16px;
    line-height: 22px;

    &:not(:first-child) {
      padding-top: 20px;

      &::before {
        top: 20px;
      }
    }

    & p {
      pointer-events: none;
    }

    & p + p + p {
      display: inline-block;
      margin-top: 10px;
      padding: 5px 10px;

      color: $color-blue;
      font-weight: 500;

      background-color: $color-alice-blue;
      border-radius: 6px;
      transform: translateX(-10px);
    }

    b {
      font-weight: 500;
    }
  }
}
