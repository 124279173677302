.equipment {
  display: flex;
  justify-content: space-between;
  padding-right: 8px;

  &:last-child .equipment__description::after {
    content: none;
  }

  &:not(:last-child) {
    margin-bottom: 28px;

    @include viewport--md {
      margin-bottom: 10px;
    }
  }

  @include viewport--md {
    &--guide-4 {
      flex-direction: column;
    }
  }
}

.equipment__note {
  padding: 5px 14px 8px;
  margin-left: -16px;
  margin-right: 20px;
  border-radius: 6px;
  color: $color-blue;
  background-color: $color-alice-blue;

  b {
    font-weight: 500;
  }

  &--guide-4 {
    display: inline-flex;
    margin-bottom: 16px;
  }
}

.equipment__description {
  position: relative;
  margin-right: 5px;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 31px;
    top: 100%;
    left: 0;
    z-index: -1;
  }

  &:hover + .equipment__img {
    opacity: 1;
  }

  @include viewport--md {
    &--guide-4 {
      margin-bottom: 16px;
    }
  }
}

.equipment__subtitle-guide-4 {
  @include viewport--lg {
    display: none;
  }
}

.equipment__subtitle-guide-4-2 {
  @include viewport--md {
    display: none;
  }
}

.equipment__img {
  width: 574px;
  flex-shrink: 0;
  transition: opacity $transition-default;

  @include viewport--lg-2 {
    margin-left: -40px;
  }

  @include viewport--sm {
    width: 100%;
  }
}

.hover .equipment__img {
  opacity: 0.2;
}
