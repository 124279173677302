.guide-8 h2 {
  margin-bottom: 12px;
}

.guide-8__note {
  margin-bottom: 18px;
  margin-left: 36px;
  font-size: 16px;
  line-height: 22px;
}
