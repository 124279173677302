// style reset
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
fieldset,
ul,
dd,
dl {
  margin: 0;
}

ul,
legend,
fieldset {
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  font-family: $font-dinpro;
  color: $color-black;

  background-color: $color-white-smoke;

  // Fix hyphenation
  word-wrap: break-word;

  // Fix rendering fonts
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Fix text zoom on Mobile Devices
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  // Fix tap color
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // Remove 300ms delay on Mobile Devices
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  @include viewport--lg-2 {
    position: relative;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

img,
video {
  display: block;
  width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

// chrome autofill background removal
// Если на проекте у инпутов используются разные цвета фонов\текста -
// удалите это из глобала и используйте локально с нужными цветами
// rgba не подойдет, сконвертируйте цвет в hex без прозрачности
// если в стилях уже используется box-shadow есть другое решение -
// задать к списку транзишенов `background-color 10000000s ease-out`
input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px $color-default-white;

  -webkit-text-fill-color: $color-default-black;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  font-family: inherit;
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {
  font-family: inherit;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches([type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-family: inherit;
  appearance: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/*.block-width {
  width: 710px;
}*/
