.guide-12 {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 35px;
}

.guide-12 h2 {
  margin-bottom: 21px;

  &::before {
    padding-right: 2px;
  }
}

.guide-12 h3 {
  margin-bottom: 4px;
  font-size: inherit;
  font-weight: 500;
}

.guide-12__wrap {
  width: 710px;
  margin-left: 36px;

  @include viewport--md {
    width: 550px;
  }

  @include viewport--sm {
    width: auto;
  }
}

.guide-12__note:not(:first-child) {
  margin-bottom: 15px;
}

.guide-12__note-break {
  @include viewport--md {
    display: none;
  }
}
