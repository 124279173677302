@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// от 1280px
@mixin viewport--lg {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

// от 1024px до 1279px
@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

// от 736px до 1023px
@mixin viewport--lg-2 {
  @media (max-width: $viewport--lg-2 - 1) {
    @content;
  }
}

// от 375px до 735px
@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

