.manual {
  max-width: 1280px;
  margin: 0 auto;
  // padding: 0 50px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  background-color: $color-white-smoke;

  // @include viewport--sm {
  //   display: none;

  // &--show {
  //   display: block;
  // }
  // }
  @media (min-width: 736px) and (orientation: landscape) {
    display: block;
  }

  @media (max-width: 599px) {
    display: none;
  }
}

// .manual__wrapper-swiper {
//   position: relative;

//   // @include viewport--lg-2 {
//   //   max-width: 696px;
//   //   margin: 0 auto;
//   // }
// }

.manual__control-wrapper {
  position: relative;
  max-width: 696px;
  margin: 0 auto;

  @include viewport--sm {
    max-width: 735px;
  }
}

.manual__wrapper {
  display: flex;
  // margin-bottom: 17px;
  border-radius: 10px;

  // @include viewport--md {
  //   margin-bottom: 0;
  // }
}

.manual__container {
  position: relative;
  // display: flex;
  max-width: 980px;
  height: auto;
  margin-left: 0;
  border-radius: 10px;
  // margin: 0 auto;

  @include viewport--md {
    max-width: 696px;
    margin: 0 auto;
  }

  @include viewport--sm {
    max-width: 735px;
    margin: 0 20px;
    // padding: 0 20px;
  }
}

.manual__list {
  // display: flex;
  // align-items: flex-start;
  // align-items: center;
  // width: 100%;
  // height: 100%;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  counter-reset: manual;
  list-style: none;
}

.manual__aside {
  position: static;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  // margin-bottom: 20px;
  margin-left: 20px;
  padding: 36px 24px;
  background-color: $color-default-white;
  border-radius: 10px;
  counter-reset: man -1;

  @include viewport--lg-2 {
    display: none;
    width: 357px;
    height: max-content;
    padding-right: 55px;
    color: $color-default-white;
    background-color: $color-blue;

    &--show {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      display: block;
      // height: 100vh;
      border-radius: 0;
    }
  }

  &.swiper-pagination-bullets .swiper-pagination-bullet {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    opacity: 1;

    &:active,
    &:focus {
      outline: none;
    }

    span {
      position: relative;
      padding-left: 26px;
      font-size: 14px;
      line-height: 18px;
      color: $color-default-black;

      @include viewport--lg-2 {
        color: $color-default-white;
      }

      &::before {
        counter-increment: man;
        content: counter(man) ".";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $color-blue;

        @include viewport--lg-2 {
          color: $color-default-white;
        }
      }
    }

    &-active {
      cursor: default;
      outline: none;
      pointer-events: none;

      span {
        font-weight: 700;
        color: $color-blue;

        @include viewport--lg-2 {
          color: $color-default-white;
        }
      }
    }
  }
}

// .manual__nav {
//   // position: sticky;
//   // top: 30px;
//   width: 100%;
//   // padding: 35px 24px 36px 22px;
//   counter-reset: man -1;
//   list-style: none;
//   line-height: 18px;
//   border-radius: 10px;
//   color: $color-default-black;
//   background-color: $color-default-white;

//   @include viewport--lg-2 {
//     background-color: transparent;
//   }
//   // &--fixed {
//   //   position: fixed;
//   //   top: 30px;
//   //   width: 280px;
//   // }
// }

// .manual__nav li {
//   position: relative;
//   padding-left: 28px;

//   &:not(:last-child) {
//     margin-bottom: 12px;
//   }

//   &::before {
//     counter-increment: man;
//     content: counter(man) ".";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 20px;
//     display: inline-flex;
//     justify-content: center;
//     font-weight: 700;
//     line-height: 18px;
//     color: $color-blue;

//     @include viewport--lg-2 {
//       color: $color-default-white;
//     }
//   }
// }

// .manual__nav-link {
//   color: $color-default-black;
//   transition: opacity $transition-default, color $transition-default;

//   @include viewport--lg-2 {
//     color: $color-default-white;
//   }

//   &:hover {
//     opacity: 0.6;
//   }
// }

// .manual__nav-link.active {
//   font-weight: 700;
//   // color: $color-blue;

//   @include viewport--lg-2 {
//     color: $color-default-white;
//   }
// }

.manual__item {
  // padding: 10px 20px 17px;
  background-color: $color-default-white;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.manual__control-buttons {
  position: absolute;
  top: 20px;
  right: 26px;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  // width: 100%;

  @media (max-width: 1279px) {
    right: 166px;
  }

  @include viewport--lg-2 {
    display: flex;
    top: -50px;
    left: 40px;
  }

  &--down {
    display: none;

    @include viewport--lg-2 {
      display: flex;
      top: -10px;
      bottom: -48px;
    }
  }
}

.manual__control-btn {
  position: relative;
  top: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 0;
  background: url("../img/sprite/right.svg") 50% no-repeat;
  border: 2px solid $color-blue;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  transition: border-color $transition-default;

  @include viewport--lg-2 {
    border: none;
  }

  &--prev {
    right: 20px;
    margin-right: 20px;
    transform: rotate(-180deg);
    // border-right: none;
    // border-bottom: none;

    @include viewport--lg-2 {
      margin-right: 0;
    }
  }

  &--next {
    right: 5px;
    // border-top: none;
    // border-left: none;
    // margin-left: auto;
  }

  // &:hover {
  //   opacity: 0.5;
  //   transition: opacity 0.2s;
  // }

  // &:active {
  //   opacity: 0.1;
  //   transition: opacity 1s;
  // }

  // &:focus {
  //   opacity: 0.1;
  //   transition: opacity 1s;
  // }
}

// .manual__number {
//   display: none;
//   max-width: 70px;
//   margin-top: 8px;
//   margin-left: -20px;

//   span {
//     font-size: 20px;
//     line-height: 20px;
//     text-align: center;
//     color: $color-blue;
//   }

//   @include viewport--lg-2 {
//     display: block;
//   }
// }

.manual__overlay {
  display: none;
  position: fixed;
  // width: 100vw;
  // height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  background-color: rgba($color-default-black, 0.6);

  @include viewport--lg-2 {
    display: none;

    &--show {
      display: block;
    }
  }
}
